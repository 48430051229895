import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { getQuiz, getUserQuiz, isQuizPassed } from '../utils/quiz'

import LoginComponent from './LoginComponent'
import { EConfig } from '../constants/EConfig'
import QuizSubmittedComponent from './QuizSubmittedComponent'
import SubmissionsChecker from './SubissionChecker'
import { getRedeemConfig } from '../utils/redeem'
import QuizCountDownTimer from './QuizCooldownTimer'

interface QuizItemProps {
  lncUser?: any,
  handleUser?: (e: any) => Promise<void>,
  addAnyEvent: (event, method?: any) => Promise<void>
}

function QuizItem({ lncUser, handleUser, addAnyEvent }: QuizItemProps) {
  const [quiz, setQuiz] = useState<any>(null)
  const [cooldown, setCooldown] = useState<any>(null)
  const [nonTgRestriction, setNonTgRestriction] = useState<any>(null)
  const [isQuizPassedVal, setIsQuizPassedVal] = useState<boolean>(false)
  const [answers, setAnswers] = useState<any>({})
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false)
  const [allAnswersCorrect, setAllAnswersCorrect] = useState(false)
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0)

  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (lncUser) {
      let token = ''
      token = lncUser.token
      getUserQuiz(token).then((response) => {
        if (response?.data?.cooldown) {
          setCooldown(response.data.cooldown)
        } else if (response?.data?.quiz) {
          setQuiz(response.data.quiz)
          setNonTgRestriction(response?.data?.non_tg_restriction)
        }
      })
      // isQuizPassed(id as string, token, setIsQuizPassedVal)
      //   .catch(console.log)
      // getUserQuiz(token, (console.log))
    }
  }, [lncUser])

  const handleAnswer = (question: any, answer: any) => {
    const isCorrect = answer.isCorrect
    setAnswers({
      ...answers, [question.id]: {
        id: answer.id,
        isCorrect
      }
    })

    let correctsCount = correctAnswersCount
    if (isCorrect) {
      correctsCount += 1
      setCorrectAnswersCount(correctsCount)
    }

    if (quiz && quiz.post_content) {
      const questions = JSON.parse(quiz.post_content).questions
      const isAllAnswered = Object.keys(questions).length === Object.keys(answers).length + 1
      const isAllCorrect = isAllAnswered && Object.keys(questions).length === correctsCount
      setAllQuestionsAnswered(isAllAnswered)
      setAllAnswersCorrect(isAllCorrect)
    }
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      await addAnyEvent('Submit LNC Quiz')

      const lncUserData = lncUser
      const answered = {}
      let corrects = 0
      for (let key in answers) {
        if (answers[key]?.isCorrect && parseInt(answers[key].isCorrect) === 1) {
          corrects += 1
        }
        answered[key] = {
          answers: [answers[key].id]
        }
      }
      const submissionData = {
        quiz_id: quiz.ID,
        name: quiz.name,
        quiz_data: quiz.post_content,
        answered: JSON.stringify(answered),
        userID: lncUserData.user.id,
        fromApp: true,
        corrects,
        result_id: '',
        current_url: window.location.href,
      }
      axios.post(`${EConfig.API_BASE_URL}/wp-json/wp-quiz/v2/play_data`, submissionData).then(response => {
        console.log(response.data)
      }).catch(error => {
        console.error(error)
      })

      setSubmitted(true)
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  const resetState = () => {
    setAnswers({})
    setAllQuestionsAnswered(false)
    setAllAnswersCorrect(false)
    setCorrectAnswersCount(0)
    setSubmitted(false)
  }

  if (!lncUser) {
    return <LoginComponent handleUser={handleUser}/>
  }
  // else if (isQuizPassedVal) {
  //   return <QuizSubmittedComponent/>
  // }
  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <Container>
      <SubmissionsChecker/>
      {cooldown ? (
        <QuizCountDownTimer cooldown={cooldown} onComplete={reloadPage}/>
      ) : (
        <Row>
          <Col md={8} className="mx-auto">
            {nonTgRestriction && (
              <h3 className="text-center">{nonTgRestriction}</h3>
            )}
            {quiz && (
              <div>
                <h1>{quiz.post_title}</h1>
                {quiz.post_content && (
                  <Form onSubmit={handleSubmit}>
                    {parseQuestions(quiz.post_content, handleAnswer, answers)}
                    {!submitted && allQuestionsAnswered && allAnswersCorrect && (
                      <div className="text-center">
                        <Button type="submit" id="button-lnc" disabled={!allQuestionsAnswered}>Submit Result</Button>
                      </div>
                    )}
                    {!submitted && allQuestionsAnswered && !allAnswersCorrect && (
                      <div className="text-center">
                        <Button id="button-failed" onClick={() => resetState()}>Please Try Again</Button>
                      </div>
                    )}
                    {submitted && (
                      isQuizPassedVal ? <p>You already have this reward! Visit our
                          <a href="https://learnnear.club/" target="_blank" rel="noreferrer"> website</a> to (L)Earn more!
                        </p>
                        : <p>💪Great job! <a href="#" onClick={reloadPage}>Check your (L)Earn score</a>🏆</p>
                    )}
                  </Form>
                )}
              </div>
            )}
          </Col>
        </Row>
      )}
    </Container>
  )
}

function parseQuestions(postContent, handleAnswer, answers) {
  const contentObj = JSON.parse(postContent)
  const questions = contentObj.questions

  return Object.keys(questions).map((questionKey) => {
    const question = questions[questionKey]
    const image = question?.image ?
      <img src={question.image} className="question-image img-fluid mb-3" alt={question.title} />
      : null

    return (
      <div key={questionKey} className="mb-4">
        {image}
        <h3>{question.title}</h3>
        <p>{question.desc}</p>
        <ul className="list-unstyled">
          {Object.values(question.answers).map((answer: any) => (
            <li key={answer.id} className="mb-2">
              <label
                className={`d-block answer-label ${answers[question.id]?.id === answer.id && answers[question.id]?.isCorrect ? 'correct-answer' : (answers[question.id]?.id === answer.id ? 'incorrect-answer' : '')}`}>
                <input
                  type="radio"
                  checked={answers[question.id]?.id === answer.id}
                  onChange={() => handleAnswer(question, answer)}
                  disabled={answers[question.id] !== undefined}
                />
                <span className="answer-text">{answer.title}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    )
  })
}

export default QuizItem
